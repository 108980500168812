<template>
  <div :style="computedStyle">
      <vue-cal
        locale="ru"
        class="vuecal--blue-theme"
        style="height: 100%"
        :time-from="timeFrom"
        :time-to="timeTo"
        :time-step="stepTime"
        :disable-views="calendar.disableViews"
        :time="calendar.time"
        :xsmall="calendar.mini"
        :hide-view-selector="calendar.mini"
        events-on-month-view="short"
        events-count-on-year-view
        :active-view="calendar.activeView"
        :events="eventsFiltered"
        :on-event-click="onEventClick">
        <!-- <template v-slot:event="{ event, view }">
          <div :style="`background: ${event.class}`">
            <div class="vuecal__event-title" v-html="event.title" />
            <div class="vuecal__event-content" v-html="event.content" />
          </div>
        </template> -->
      </vue-cal>
    <slot></slot>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard'
import PropertiesMixin from '@/components/InterfaceEditor/components/PropertiesMixin'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'
import { arraysEqualByObjects } from '@/helpers'
import VueCal from 'vue-cal'
import 'vue-cal/dist/i18n/ru.js'
import 'vue-cal/dist/vuecal.css'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import RegistryCard from '@/components/RegistryCard/index.vue'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'

export default {
  name: 'a-calendar',

  components: { VueCal, Dashboard, RegistryCard },

  inject: {
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    getParentDashboard: {
      default: () => {}
    },
    getViewer: {
      default: () => {
        return {}
      }
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    },
    isEditor: {
      default: () => false
    }
  },

  mixins: [PropertiesMixin, refreshComponentsMixin],

  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },

    CSS: {
      type: String,
      description: 'css_style'
    },

    calendar: {
      type: Object,
      default () {
        return {
          events: {
            start: null,
            end: null,
            title: null,
            content: null,
            class: null
          },
          time: false,
          mini: false,
          disableViews: [],
          activeView: 'week',
          registryId: null,
          requestId: null,
          extendedObjectId: null,
          type: 'registry'
        }
      },
      editor: 'Calendar'
    },

    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: false,
        showEqualsTypes: false
      }
    }
  },

  data () {
    return {
      events: [],
      firstLoadingCalendar: true,
      promiseWatchFilters: undefined,
      promiseInitCalendar: undefined,
      saveFilter: undefined
    }
  },

  computed: {
    eventsFiltered () {
      return this.events
    },

    timeFrom () {
      if (this.calendar.timeFrom) {
        return +this.calendar.timeFrom * 60
      }
      return 8 * 60
    },

    timeTo () {
      if (this.calendar.timeTo) {
        return +this.calendar.timeTo * 60
      }
      return 23 * 60
    },

    stepTime () {
      if (this.calendar.stepTime) {
        return +this.calendar.stepTime
      }
      return 30
    },

    computedStyle () {
      let css = this.CSS
      if (!css) {
        css += `;width: 100%`
        css += `;height: 100%`
      }
      return css
    },

    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.calendar
      )

      const filters = builder.buildAsApiQl()
      if (filters.length === 0 && this.isEditor()) {
        return {
          limit: 10
        }
      }
      if (filters.length > 0 && this.isEditor()) {
        return {
          where: {
            and: [...filters]
          },
          limit: 20
        }
      }

      return filters.length > 0
        ? {
          where: {
            and: [...filters]
          }
        }
        : null

      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = `=`
      //     //     // if (item.isXref) {
      //     //     //   type = `eqx`
      //     // }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         filters.push(`${item.alias}${type}${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     } else if (item.type === 'current_user' && item.alias) {
      //       filters.push(`${item.alias}${type}${this.$store.getters['Authorization/userId']}`)
      //     }
      //   })
      // }
      // return filters
    }
  },
  watch: {
    dataFilters: {
      async handler (val) {
        await this.promiseWatchFilters
        await this.promiseInitCalendar
        this.promiseWatchFilters = new Promise(async (resolve, reject) => {
          if (this.calendar.type === 'registry' && this.calendar.registryId && this.calendar.events.start) {
            await this.getRegistryEvents()
            resolve()
          } else if (this.calendar.type === 'requests' && this.calendar.requestsId && this.calendar.events.start) {
            this.firstLoadingCalendar = false
            this.saveFilter = val
            // if (this.saveFilter?.where?.and && this.dataFilters?.where?.and) {
            // if (!arraysEqualByObjects(this.saveFilter.where.and, this.dataFilters.where.and)) {
            await this.getRequestsEvents(this.calendar.requestsId)
            resolve()
            // }
            // }
          } else if (this.calendar.type === 'extended_object' && this.calendar.extendedObjectId && this.calendar.events.start) {
            this.firstLoadingCalendar = false

            await this.getExtendedObjectEvents(this.calendar.extendedObjectId)
            resolve()
          }
        })
      }

    },

    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },

  mounted () {
    this.$nextTick(this.initCalendar)
  },

  methods: {
    async onEventClick (event) {
      if (this.calendar.action && this.calendar.action.type) {
        try {
          await ActionExecutor.execute(this, { readonly: this.calendar.readonly, pluginName: this.calendar.pluginName, action: this.calendar.action, event: event }, event.eventsData)
        } catch (error) {
          console.error('Ошибка действия кнопки', error)
        }
      } else {
        if (this.calendar.actionType === 'open_dashboard' && this.calendar.dashboard.id) {
          if (this.calendar.dashboard.isFullscreen) {
            this.openDashboardCard(this.action.dashboard.id, this.calendar.dashboard.window_title)
          } else {
            const h = this.$createElement
            let customClass = 'custom_scrollbar '
            if (this.calendar.dashboard.window_width) {
              customClass += `dashboard_window_width_${this.calendar.dashboard.window_width}`
            }
            this.$msgbox({
              title: this.calendar.dashboard.window_title,
              customClass: customClass,
              message: h('dashboard', { props: { id: this.calendar.dashboard.id, parentContext: this, model: JSON.parse(JSON.stringify(this.getModel())), msgbox: 'msgbox' }, key: this.generateGuid() }),
              showCancelButton: false,
              showConfirmButton: false,
              closeOnClickModal: false
            })
          }
        } else if (this.calendar.actionType === 'open_card' && this.calendar.type === 'registry') {
          if (
            !this.calendar.card.registryId ||
            !this.calendar.card.type ||
            (!this.calendar.card.fieldId && !this.calendar.card.constantRecordId) ||
            !this.calendar.card.cardId
          ) {
            console.warn('wrong parameters', this.calendar.card)
            return false
          }
          const readOnly = this.calendar.card.type === 'read'
          let recordId = null
          let initialData = {}
          if (this.calendar.card.type === 'update' || this.calendar.card.type === 'read') {
            if (this.calendar.card.constantRecordId) {
              recordId = this.calendar.card.constantRecordId
            } else {
              recordId = event.id
            }
            if (!recordId) {
              console.warn(`recordId doesn't set = ${this.calendar.card.fieldId}, constant = ${this.calendar.card.constantRecordId}`)
              return false
            }
          }
          if (this.calendar.card.isWindow) {
            const h = this.$createElement
            let customClass = 'custom_scrollbar '
            if (this.calendar.card.windowWidth) {
              customClass += `dashboard_window_width_${this.calendar.card.windowWidth}`
            }
            let me = this
            this.$msgbox({
              title: this.calendar.card.windowTitle,
              customClass: customClass,
              message: h('registry-card', { props: {
                cardId: this.calendar.card.cardId,
                registryId: this.calendar.card.registryId,
                readonly: readOnly,
                recordId: recordId,
                initialData: initialData
              },
              on: {
                cancelChanges: function () {
                  me.$msgbox.close()
                }
              },
              key: this.generateGuid() }),
              showCancelButton: false,
              showConfirmButton: false,
              closeOnClickModal: false
            })
          } else {
            this.openRegistryCard({
              registryId: this.calendar.card.registryId,
              cardId: this.calendar.card.cardId,
              cardName: '',
              recordId: recordId,
              initialData: initialData,
              registry: { readonly: readOnly, addRecord: () => {}, updateRecord: () => {} }
            })
          }
        } else if (this.calendar.actionType === 'open_card' && (this.calendar.type === 'requests' || this.calendar.type === 'extended_object')) {
          let registryId = event.eventsData[`${this.calendar.card.registryId}`]
          let cardId = event.eventsData[`${this.calendar.card.cardId}`]
          let recordId = event.eventsData[`${this.calendar.card.fieldId}`]
          console.warn(`registryId: ${registryId}; cardId: ${cardId}; recordId: ${recordId}`)
          if (!registryId || !cardId || !recordId) {
            console.warn('wrong parameters', event.eventsData)
            return false
          }
          this.openRegistryCard({
            registryId: registryId,
            cardId: cardId,
            cardName: '',
            recordId: recordId
          })
        }
      }
    },

    createEvents (events) {
      let start, end, title, content, classColor
      if (this.calendar.type === 'registry') {
        start = `attr_${this.calendar.events.start}_`
        end = `attr_${this.calendar.events.end}_`
        title = `attr_${this.calendar.events.title}_`
        content = `attr_${this.calendar.events.content}_`
        classColor = `attr_${this.calendar.events.class}_`
      } else if (this.calendar.type === 'requests' || this.calendar.type === 'extended_object') {
        start = `${this.calendar.events.start}`.toLowerCase()
        end = `${this.calendar.events.end}`.toLowerCase()
        title = `${this.calendar.events.title}`.toLowerCase()
        content = `${this.calendar.events.content}`.toLowerCase()
        classColor = `${this.calendar.events.class}`.toLowerCase()
      }

      this.events = events.reduce((acc, elem) => {
        let startDate = elem[start] || ''
        let endDate = elem[end] || ''
        let titleEvents
        if (elem[start]) {
          // в строке есть время
          if (this.calendar.time && !elem[start].length > 12) {
            startDate = elem[start] + ' 00:00'
          }
          if (this.calendar.time && !elem[end].length > 12) {
            endDate = elem[end] + ' 24:00'
          }
          if (!endDate) {
            endDate = startDate
          }
          try {
            titleEvents = JSON.parse(elem[title])[0].name
          } catch (error) {
            titleEvents = elem[title]
          }
          // console.log(startDate)
          // console.log(titleEvents)
          acc.push(
            {
              id: elem.id,
              eventsData: elem,
              start: startDate,
              end: endDate,
              title: titleEvents,
              content: elem[content],
              class: elem[classColor] || 'blue'
            }
          )
        }
        return acc
      }, [])
    },

    // Query
    async getRequests (id) {
      const response = await this.$http
        .post(`${this.$config.api}/datawarehouseservice/query/${id}`, this.dataFilters, { hideNotification: true })

      return response.data
    },

    async getRequestsEvents (id) {
      const data = await this.getRequests(id)

      await this.createEvents(data)
    },

    // Extended object
    async getExtendedObject (id) {
      const response = await this.$http
        .post(`${this.$config.api}/datawarehouseservice/extended_object/${id}`, this.dataFilters, { hideNotification: true })

      return response.data
    },

    async getExtendedObjectEvents (id) {
      const data = await this.getExtendedObject(id)

      await this.createEvents(data)
    },

    // Registry
    async getRegistry (entityId) {
      const response = await this.$http
        .post(`${this.$config.api}/registryservice/registry/${entityId}`, this.dataFilters, { hideNotification: true })

      return response.data.data
    },

    async getRegistryEvents () {
      const data = await this.getRegistry(this.calendar.registryId)

      await this.createEvents(data)
    },

    async initCalendar () {
      this.promiseInitCalendar = new Promise(async (resolve, reject) => {
        if (this.calendar.type === 'registry' && this.calendar.registryId && this.calendar.events.start) {
          await this.getRegistryEvents()
          resolve()
        } else if (this.calendar.type === 'requests' && this.calendar.requestsId && this.calendar.events.start) {
          if (this.firstLoadingCalendar) {
            this.firstLoadingCalendar = false
            await this.getRequestsEvents(this.calendar.requestsId)
            resolve()
          }
        } else if (this.calendar.type === 'extended_object' && this.calendar.extendedObjectId && this.calendar.events.start) {
          if (this.firstLoadingCalendar) {
            this.firstLoadingCalendar = false

            await this.getExtendedObjectEvents(this.calendar.requestsId)
            resolve()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .vuecal__event {
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    font-size: 15px;

    &.blue {
      background-color: #ABEBF4;
      border: 1px solid #66C3D0;
      border-left: 4px solid #66C3D0;
    }
    &.red {
      background-color: #FFD5D7;
      border: 1px solid #EC7981;
      border-left: 4px solid #EC7981;
    }
    &.orange {
      background-color: #FFD5A6;
      border: 1px solid #ECA351;
      border-left: 4px solid #ECA351;
    }
    &.yellow {
      background-color: #FFF1A4;
      border: 1px solid #F1D11F;
      border-left: 4px solid #F1D11F;
    }
    &.green {
      background-color: #BAF5B9;
      border: 1px solid #7BCD7A;
      border-left: 4px solid #7BCD7A;
    }
    &.purple {
      background-color: #D4CEFD;
      border: 1px solid #9B8EE4;
      border-left: 4px solid #9B8EE4;
    }
    &.grey {
      background-color: #E1E5EA;
      border: 1px solid #ACB3BF;
      border-left: 4px solid #ACB3BF;
    }
      .vuecal__event-title {
        text-align: left;
        font-weight: bold;
        margin: 4px 0 2px;
        color:#2D3943;
      }
      .vuecal__event-content {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #2D3943;
        text-align: left;
      }
  }
  .vuecal__event-time {
    display: inline-block;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

</style>
